"use client";

import { usePathname } from "next/navigation";
import Link from "next/link";

const routes = [
  {
    title: "Home",
    href: "/",
  },
  {
    title: "Events",
    href: "/events",
  },
  {
    title: "About",
    href: "/about",
  },
  {
    title: "Questions",
    href: "/faq",
  }
];

export default function Navbar() {
  const pathname = usePathname()

  return (
    <nav
      className={
        "flex items-center justify-evenly w-full text-primaryText text-lg tracking-wide ivymode-thin"
      }
    >
      {routes.map((route, index) => (
        <Link
          key={index}
          href={route.href}
          className={pathname === route.href ? "scale-150 underline transition ease-in-out duration-300" : "transition ease-in-out hover:-translate-y-1 duration-300"}
        >
          {route.title}
        </Link>
      ))}
    </nav>
  );
}
